import React, { useState, useEffect, useRef } from "react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { useLocation } from "react-router-dom";
import "./App.scss";
import LoadingScreen from "./LoadingScreen";
import axios from "axios";
const servers = {
  florida: `65.108.121.222`,
  geneva: `65.108.107.232`,
  hamburg: `176.9.22.103`,
  imola: `65.108.6.102`,
};

const serverIP = servers.hamburg;

const App = () => {
  let query = new URLSearchParams(window.location.search);
  let uniqueID = query.get("uniqueID");
  const [loaded, setLoaded] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [tvcTimecodes, setTvcTimecodes] = useState([]);
  const [lBandTimecodes, setLBandTimecodes] = useState([]);
  const playerRef = useRef();

  useEffect(() => {
    axios
      .post(`http://${serverIP}:8080/getInfo`, { uniqueID: uniqueID })
      .then((response) => {
        console.log("API Response:", response.data);
        if (!response?.data?.videoUrl) {
          console.error("No video found");
          return;
        }
        setVideoUrl(response.data.videoUrl); // Устанавливаем URL стрима
        setTvcTimecodes(response.data.tvcTimecodes);
        setLBandTimecodes(response.data.lBandTimecodes);
        setLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [uniqueID]);

  const player = {
    type: "video",
    sources: [
      {
        src: videoUrl, // Используем URL стрима для проигрывания видео
        type: "video/mp4",
      },
    ],
    options: {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "pip",
        "airplay",
        "fullscreen",
      ],
    },
  };

  const handleTimecodeClick = (timecode) => {
    const [hours, minutes, seconds] = timecode.split(":").map(Number);
    const timeInSeconds = hours * 3600 + minutes * 60 + seconds;
    if (playerRef.current && playerRef.current.plyr) {
      playerRef.current.plyr.currentTime = timeInSeconds;
    }
  };

  if (!loaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="container">
        <div className="timecodeContainer">
          <h3>TVC Timecodes</h3>
          <div className="timecodes">
            {tvcTimecodes.map((timecode) => (
              <p className="timecode" key={timecode} onClick={() => handleTimecodeClick(timecode)}>
                {timecode}
              </p>
            ))}
          </div>
        </div>
        <div className="timecodeContainer">
          <h3>L Band Timecodes</h3>
          <div className="timecodes">
            {lBandTimecodes.map((timecode) => (
              <p className="timecode" key={timecode} onClick={() => handleTimecodeClick(timecode)}>
                {timecode}
              </p>
            ))}
          </div>
        </div>
        <Plyr source={player} ref={playerRef} />
      </div>
    );
  }
};

export default App;
